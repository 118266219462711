import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import dynamic from 'next/dynamic';
// import Description from './description';

const Description = dynamic(() => import('./description'), { ssr: false });

interface FinestBrandProps {
  data: any;
}

const FinestBrand: React.FC<FinestBrandProps> = ({ data }) => {
  return (
    <>
      <section className={styles.finestbrandsection}>
        <div className={styles['finestbrandinner']}>
          <div className="container-fluid">
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.finestbrandheading}>
                  <h2>{data?.title}</h2>

                  <Description description={data?.description} />

                  <p style={{ display: 'none' }}>
                    Buy{' '}
                    <Link href="/furniture" className="text-link-primary">
                      Furniture
                    </Link>{' '}
                    Online from our extensive collection of wooden furniture
                    units to give your home an elegant touch at affordable
                    prices. We offer a wide range of wooden furniture online to
                    suit your home interiors. You can either buy furniture
                    carved out of solid wood material from our offerings or get
                    it customized to match your requirements. Buy furniture
                    online and find the features, functionality, dimensions of
                    the products, all with great ease. You can also avail the
                    exciting deals and discount offers on our online furniture
                    store.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.finestbrandcontent}>
              <div className="wdRow wdgx-lg-4 wdgx-md-3">
                {data?.items?.map((poster: any, index: number) => (
                  <div
                    className="wdCol-lg-4 wdCol-md-4 wdCol-sm-6 wdCol-12"
                    key={index}
                  >
                    <Link
                      href={poster.href}
                      className={styles.finestbrandcard}
                      aria-label={poster.ariaLabel}
                    >
                      <div className={styles.finestbrandimg}>
                        <figure>
                          <Image
                            src={poster.imgSrc}
                            alt={poster.alt}
                            title={poster.title}
                            width={585}
                            height={508}
                            quality={100}
                            loading="lazy"
                          />
                        </figure>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.seasonsection}>
        <div className={styles.seasoninner}>
          <div className="container-fluid">
            <div className={styles.seasonimg}>
              <Link href="/offline-furniture-store">
                <Image
                  quality={100}
                  loading="lazy"
                  width={1827}
                  height={206}
                  alt=""
                  src="/images/home-new1/strip-furniture-banner.jpg"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinestBrand;
